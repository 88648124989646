import axios from 'axios'
import { useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

export default function Meeting() {
    const location = useLocation()
    useEffect(() => {
        const meetingApi = `/api/v1` + location.pathname + location.search
        console.log(location.pathname + location.search)
        axios
            .get(meetingApi)
            .then((res) => {
                alert('会议室释放成功')
            })
            .catch((err) => {
                alert('会议室释放失败')
            })
    }, [])
    return <div></div>
}
