import React from 'react'
import Error from 'components/Error'

const props = {
    status: '404',
    title: '404',
    subTitle: 'Not found',
}

export default function NotFound() {
    return <Error {...props} />
}
