import axios from 'axios'
import CreateMeetingForm from 'components/CreateMeetingForm'
import { LoadImage } from 'components/LoadImage'
import MeetingRoomRelease from 'components/MeetingRoomRelease'
import withRouter from 'components/withRouter'
import React, { Component } from 'react'
import { ParseDateTime } from 'util/time'
import reset from './css/reset.module.css'
import style from './css/style.module.css'

// references: https://codepen.io/heightzhang/pen/ePqLJm

var currentDate = ''
var currentTime = ''

var intervalTimer
var intervalMeeting

var pressTimeid

class Status extends Component {
    // backend = 'http://conference2.cowarobot.com'
    // //backend = 'http://127.0.0.1:1080'
    // apiReservations = this.backend + '/api/v1/reservations/' + this.props.match.room

    apiReservations = '/api/v1/reservations/' + this.props.match.room
    apiMeetingRoom = '/api/v1/rooms/' + this.props.match.room
    apiRoomRelease = '/api/v1/room/release'
    room = '' // 当前会议室的名字

    state = {
        reservations: [],
        disabled: false,
        openCreateMeeting: false,
        openReleaseRoom: false,
        currentIndex: 0
    }

    handleTouchStart = index => {
        pressTimeid = setTimeout(() => {
            console.warn(this.state.reservations[index])
            this.setState({
                ...this.state,
                currentIndex: index,
                openReleaseRoom: true
            })
            // Modal.confirm({
            //     title: '你确定释放当前会议室吗?',
            //     cancelText: '释放',
            //     confirmText: '取消',
            //     content: (
            //         <div
            //             style={{
            //                 display: 'flex',
            //                 flexDirection: 'column',
            //                 justifyContent: 'center',
            //                 textAlign: 'center'
            //             }}
            //         >
            //             <div>
            //                 {ParseDateTime(this.state.reservations[index].meeting_start_time).time}-
            //                 {ParseDateTime(this.state.reservations[index].meeting_end_time).time}
            //             </div>
            //             <div>{this.state.reservations[index].meeting_title}</div>
            //             <div>{this.state.reservations[index].department}</div>
            //             <div>{this.state.reservations[index].reserver}</div>
            //         </div>
            //     ),
            //     onCancel: () => {
            //         axios
            //             .post(this.apiRoomRelease, {
            //                 meeting_room: decodeURIComponent(this.room),
            //                 start_timestamp: this.state.reservations[index].meeting_start_time,
            //                 end_timestamp: this.state.reservations[index].meeting_end_time
            //             })
            //             .then(() => {
            //                 message.success('释放会议室成功')
            //             })
            //             .catch(err => {
            //                 console.error(err)
            //                 message.error('释放会议室失败')
            //             })
            //     }
            // })
        }, 1000)
    }
    handleTouchEnd = () => {
        clearTimeout(pressTimeid)
    }

    componentDidMount() {
        const url = new URL(window.location)
        // url.pathname 的最后一个参数就是会议室的名字
        const pathname = url.pathname.split('/')
        this.room = pathname[pathname.length - 1]
        console.log('current room name: ', this.room) // 本来是需要对 url 进行解码的, 但是后端已经做过了,前端无所谓做不做

        document.title = '会议室扫码开门与预约系统'
        // alert(window.innerWidth)
        // alert(window.innerHeight)

        // 获取当前会议室的状态
        axios.get(this.apiMeetingRoom).then(res => {
            this.setState({
                ...this.state,
                disabled: res.data.data.disabled
            })
            // 如果会议室没有停用才获取当前会议室的会议预定信息.
            if (!res.data.data.disabled) {
                // 获取当前会议室的会议预定信息
                axios.get(this.apiReservations).then(res => {
                    //console.log(res.data.data)
                    var arr = []
                    var newarr = []
                    arr = res.data.data
                    console.log('arr: ', arr)
                    newarr = arr.filter(item => {
                        return item.meeting_room === this.props.match.room
                    })

                    console.log('newarr: ', newarr)
                    if (newarr.length !== 0) {
                        this.setState({
                            ...this.state,
                            reservations: newarr
                        })
                        //console.log(this.state.reservations)
                        console.log(this.state.reservations)
                        console.log(ParseDateTime(this.state.reservations[0]?.meeting_start_time))
                        console.log(ParseDateTime(this.state.reservations[0]?.meeting_end_time))
                    } else {
                        this.setState({
                            ...this.state,
                            reservations: []
                        })
                    }
                })
            }
        })

        clearInterval(intervalTimer)
        intervalTimer = setInterval(() => {
            if (this.state.disabled) {
                return
            }
            var dt = new Date()
            var year = dt.getFullYear() // 获取年
            var month = dt.getMonth() + 1 // 获取月, 从 Date 对象返回月份(0 ~ 11)，故在此处+1
            // getDay: day of week
            // getDate: day of month
            var day = dt.getDate()
            var hour = dt.getHours()
            var minute = dt.getMinutes()
            var second = dt.getSeconds()
            var mydate = year + '年' + month + '月' + day + '日'
            var mytime = hour + '时' + minute + '分' + second + '秒'
            //console.log(mydate)
            //console.log(mytime)
            //currentDate = mydate
            //currentTime = mytime
            document.getElementById('date').innerHTML = mydate
            document.getElementById('time').innerHTML = mytime
        }, 1000)

        clearInterval(intervalMeeting)
        intervalMeeting = setInterval(() => {
            // 获取当前会议室的状态
            axios.get(this.apiMeetingRoom).then(res => {
                // console.log('----------- disabled', res.data.data.disabled)
                this.setState({
                    ...this.state,
                    disabled: res.data.data.disabled
                })
                // 如果会议室没有停用才获取当前会议室的会议预定信息.
                if (!res.data.data.disabled) {
                    // 获取当前会议室的会议预定信息
                    axios.get(this.apiReservations).then(res => {
                        //console.log(res.data.data)
                        var arr = []
                        var newarr = []
                        arr = res.data.data
                        console.log('arr: ', arr)
                        newarr = arr.filter(item => {
                            // 过滤出当前会议室的预定会议.
                            return item.meeting_room === this.props.match.room
                        })

                        console.log('newarr: ', newarr)
                        if (newarr.length !== 0) {
                            this.setState({
                                ...this.state,
                                reservations: newarr
                            })
                            console.log(this.state.reservations)
                            console.log(ParseDateTime(this.state.reservations[0]?.meeting_start_time))
                            console.log(ParseDateTime(this.state.reservations[0]?.meeting_end_time))
                        } else {
                            this.setState({
                                ...this.state,
                                reservations: []
                            })
                        }
                    })
                }
            })
        }, 5000)
    }

    componentWillUnmount() {
        clearInterval(intervalTimer)
        clearInterval(intervalMeeting)
    }

    render() {
        //if (this.state.reservations.length === 0) {
        //    return <SpinLoading color="primary" style={{ '--size': '96px' }} />
        //}
        return this.state.disabled ? (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: window.innerHeight,
                    color: 'rgb(120,120,120)',
                    fontSize: 80,
                    fontWeight: 'bold'
                }}
            >
                会议室已停用
            </div>
        ) : (
            <div>
                <div className={style.header}>
                    <div className={`${style.container} ${reset.clearfix}`}>
                        <div className={`${style.logo} ${reset.leftfix}`}>
                            <img src={LoadImage('logo')} alt='logo' />
                        </div>
                        <div className={`${style.room} ${reset.leftfix}`}>{this.props.match.room}</div>
                        <ul className={`${style.datetime} ${reset.leftfix}`}>
                            <li id='date'>{currentDate}</li>
                            <li id='time'>{currentTime}</li>
                        </ul>
                    </div>
                </div>

                <div className={style.main}>
                    <div className={`${style.container} ${reset.clearfix}`}>
                        <div className={`${style.meetingInfo} ${reset.leftfix}`}>
                            <div className={style.current}>
                                <CurrentMeeting reservations={this.state.reservations} />
                            </div>
                            <div className={style.qrcode}>
                                <span>扫码开门</span>
                                <img src={LoadImage(this.props.match.room)} alt='会议室二维码' />
                            </div>
                        </div>
                        <div className={`${style.meetingList} ${reset.leftfix}`}>
                            <table>
                                {/* <caption>当天会议安排</caption> */}
                                <caption>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <span style={{ flex: 9 }}>当前会议安排</span>
                                        {/* <span style={{ marginLeft: 10, fontSize: '1rem' }}>wokao</span> */}
                                        <div
                                            style={{
                                                flex: 1,
                                                fontSize: '0.9rem',
                                                color: '#fff',
                                                borderLeft: '2px solid #fff',
                                                fontWeight: 'bolder',
                                                cursor: 'pointer',
                                                userSelect: 'none',
                                                backgroundColor: 'rgb(20,112,159)',
                                                borderTopRightRadius: 16
                                            }}
                                            onClick={() => this.setState({ ...this.state, openCreateMeeting: true })}
                                        >
                                            创建会议
                                        </div>
                                    </div>
                                </caption>
                                <thead>
                                    <tr>
                                        <th>会议时间</th>
                                        <th>会议主题</th>
                                        <th>预约部门</th>
                                        <th>预定人</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.reservations.length > 0 ? (
                                        <tr
                                            onTouchStart={() => this.handleTouchStart(0)}
                                            onTouchEnd={this.handleTouchEnd}
                                        >
                                            <td>
                                                {ParseDateTime(this.state.reservations[0].meeting_start_time).time}-
                                                {ParseDateTime(this.state.reservations[0].meeting_end_time).time}
                                            </td>
                                            <td>{this.state.reservations[0].meeting_title}</td>
                                            <td>{this.state.reservations[0].department}</td>
                                            <td>{this.state.reservations[0].reserver}</td>
                                        </tr>
                                    ) : (
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    )}
                                    {this.state.reservations.length > 1 ? (
                                        <tr
                                            onTouchStart={() => this.handleTouchStart(1)}
                                            onTouchEnd={this.handleTouchEnd}
                                        >
                                            <td>
                                                {ParseDateTime(this.state.reservations[1].meeting_start_time).time}-
                                                {ParseDateTime(this.state.reservations[1].meeting_end_time).time}
                                            </td>
                                            <td>{this.state.reservations[1].meeting_title}</td>
                                            <td>{this.state.reservations[1].department}</td>
                                            <td>{this.state.reservations[1].reserver}</td>
                                        </tr>
                                    ) : (
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    )}
                                    {this.state.reservations.length > 2 ? (
                                        <tr
                                            onTouchStart={() => this.handleTouchStart(2)}
                                            onTouchEnd={this.handleTouchEnd}
                                        >
                                            <td>
                                                {ParseDateTime(this.state.reservations[2].meeting_start_time).time}-
                                                {ParseDateTime(this.state.reservations[2].meeting_end_time).time}
                                            </td>
                                            <td>{this.state.reservations[2].meeting_title}</td>
                                            <td>{this.state.reservations[2].department}</td>
                                            <td>{this.state.reservations[2].reserver}</td>
                                        </tr>
                                    ) : (
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    )}
                                    {this.state.reservations.length > 3 ? (
                                        <tr
                                            onTouchStart={() => this.handleTouchStart(3)}
                                            onTouchEnd={this.handleTouchEnd}
                                        >
                                            <td>
                                                {ParseDateTime(this.state.reservations[3].meeting_start_time).time}-
                                                {ParseDateTime(this.state.reservations[3].meeting_end_time).time}
                                            </td>
                                            <td>{this.state.reservations[3].meeting_title}</td>
                                            <td>{this.state.reservations[3].department}</td>
                                            <td>{this.state.reservations[3].reserver}</td>
                                        </tr>
                                    ) : (
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    )}
                                    {this.state.reservations.length > 4 ? (
                                        <tr
                                            onTouchStart={() => this.handleTouchStart(4)}
                                            onTouchEnd={this.handleTouchEnd}
                                        >
                                            <td>
                                                {ParseDateTime(this.state.reservations[4].meeting_start_time).time}-
                                                {ParseDateTime(this.state.reservations[4].meeting_end_time).time}
                                            </td>
                                            <td>{this.state.reservations[4].meeting_title}</td>
                                            <td>{this.state.reservations[4].department}</td>
                                            <td>{this.state.reservations[4].reserver}</td>
                                        </tr>
                                    ) : (
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    )}
                                    {this.state.reservations.length > 5 ? (
                                        <tr
                                            onTouchStart={() => this.handleTouchStart(5)}
                                            onTouchEnd={this.handleTouchEnd}
                                        >
                                            <td>
                                                {ParseDateTime(this.state.reservations[5].meeting_start_time).time}-
                                                {ParseDateTime(this.state.reservations[5].meeting_end_time).time}
                                            </td>
                                            <td>{this.state.reservations[5].meeting_title}</td>
                                            <td>{this.state.reservations[5].department}</td>
                                            <td>{this.state.reservations[5].reserver}</td>
                                        </tr>
                                    ) : (
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    )}
                                    {this.state.reservations.length > 6 ? (
                                        <tr
                                            onTouchStart={() => this.handleTouchStart(6)}
                                            onTouchEnd={this.handleTouchEnd}
                                        >
                                            <td>
                                                {ParseDateTime(this.state.reservations[6].meeting_start_time).time}-
                                                {ParseDateTime(this.state.reservations[6].meeting_end_time).time}
                                            </td>
                                            <td>{this.state.reservations[6].meeting_title}</td>
                                            <td>{this.state.reservations[6].department}</td>
                                            <td>{this.state.reservations[6].reserver}</td>
                                        </tr>
                                    ) : (
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <CreateMeetingForm
                    open={this.state.openCreateMeeting}
                    onOk={() => this.setState({ ...this.state, openCreateMeeting: false })}
                    onCancel={() => this.setState({ ...this.state, openCreateMeeting: false })}
                />
                <MeetingRoomRelease
                    open={this.state.openReleaseRoom && this.state.reservations[this.state.currentIndex] !== undefined}
                    onOk={() => this.setState({ ...this.state, openReleaseRoom: false })}
                    onCancel={() => this.setState({ ...this.state, openReleaseRoom: false })}
                    reservation={this.state.reservations[this.state.currentIndex]}
                />

                <div className={style.footer}>
                    <div className={style.container}>
                        <div className={style.marquee}>
                            <marquee behavior='' direction=''>
                                长按会议记录取消会议，但只可取消当前时间段会议。
                            </marquee>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
class CurrentMeeting extends Component {
    render() {
        // 当前时间下: 该会议室没有预定会议
        if (this.props.reservations.length === 0) {
            return (
                <div>
                    <div className={style.title}>当前会议</div>
                    <div className={style.roomIdle}>空闲中</div>
                </div>
            )
        }
        console.log('CurrentMeeting: ', JSON.stringify(this.props.reservations[0]))
        var now = new Date()
        var start = ParseDateTime(this.props.reservations[0].meeting_start_time).dt
        var end = ParseDateTime(this.props.reservations[0].meeting_end_time).dt
        if (start < now && now < end) {
            // 当前时间下: 该会议室有预定会议, 并且正在开会.
            return (
                <div>
                    <div className={style.title}>当前会议</div>
                    <div className={style.content}>
                        <div className={style.item1}>会议主题: {this.props.reservations[0].meeting_title}</div>
                        <ul className={style.item2}>
                            <li>预约部门：{this.props.reservations[0].department}</li>
                            <li>预约人：{this.props.reservations[0].reserver}</li>
                            <li>
                                会议时间：
                                {ParseDateTime(this.props.reservations[0].meeting_start_time).time}-
                                {ParseDateTime(this.props.reservations[0].meeting_end_time).time}
                            </li>
                        </ul>
                    </div>
                    <div className={style.status}>会议中</div>
                </div>
            )
        } else {
            // 当前时间下: 该会议室虽然有预定会议, 并且还没开会.
            return (
                <div>
                    <div className={style.title}>当前会议</div>
                    <div className={style.roomIdle}>空闲中</div>
                </div>
            )
        }
    }
}

export default withRouter(Status)

// safari new Date()不兼容的问题
// 它奶奶的, 这个不兼容性问题我调试了无数遍了.
// https://cloud.tencent.com/developer/article/1654126
//function parseDateTime(str) {
//    var newstr = str.replaceAll('-', '/')
//    var dt = new Date(newstr)
//
//    var year = dt.getFullYear()
//    var month = dt.getMonth() + 1
//    var day = dt.getDay()
//    var hour = dt.getHours()
//    var minute = dt.getMinutes()
//    var second = dt.getSeconds()
//
//    //var date = year + '年' + month + '月' + day + '日'
//    //var time = hour + '时' + minute + '分' + second + '秒'
//
//    if (month < 10) {
//        month = '0' + month
//    }
//    if (day < 10) {
//        day = '0' + day
//    }
//    if (hour < 10) {
//        hour = '0' + hour
//    }
//    if (minute < 10) {
//        minute = '0' + minute
//    }
//    if (second < 10) {
//        second = '0' + second
//    }
//    var date = year + '-' + month + '-' + day
//    var time = hour + ':' + minute + ':' + second
//    return { date, time, dt }
//}
