import { Button, Divider, Flex, message, Modal } from 'antd'
import axios from 'axios'
import { useState } from 'react'
import { ParseDateTime } from 'util/time'
import CreateMeetingForm from './CreateMeetingForm'
import { IoMdTime } from 'react-icons/io'
import { MdOutlineMeetingRoom } from 'react-icons/md'
import { SlNote } from 'react-icons/sl'
import { PiUsers } from 'react-icons/pi'

const MeetingRoomRelease = ({ open, onOk, onCancel, reservation }) => {
    const [openCreate, setOpenCreate] = useState(false)
    const [startTimestamp, setStartTimestamp] = useState()
    const [endTimestamp, setEndTimestamp] = useState()

    const [loadingRelease, setLoadingRelease] = useState(false)
    const [loadingReleaseAndDelete, setLoadingReleaseAndDelete] = useState(false)

    const apiRoomRelease = '/api/v1/room/release'
    const url = new URL(window.location)
    const pathname = url.pathname.split('/')
    const room = pathname[pathname.length - 1]

    const handleReleaseRoom = (startTimestamp, endTimestamp) => {
        setLoadingRelease(true)
        axios
            .post(apiRoomRelease, {
                meeting_room: decodeURIComponent(room),
                start_timestamp: startTimestamp,
                end_timestamp: endTimestamp,
                attendees: reservation.reserver,
                summary: reservation.meeting_title
            })
            .then(res => {
                onOk()
                setLoadingRelease(false)
                if (res.data.code === 0) {
                    message.success('释放会议室成功')
                } else {
                    message.error('释放会议失败: ' + res.data.msg)
                }
            })
            .catch(err => {
                console.error(err)
                message.error('释放会议室失败')
                setLoadingRelease(false)
            })
    }
    const handleReleaseRoomAndCreate = (startTimestamp, endTimestamp) => {
        setLoadingReleaseAndDelete(true)
        axios
            .post(apiRoomRelease, {
                meeting_room: decodeURIComponent(room),
                start_timestamp: startTimestamp,
                end_timestamp: endTimestamp
            })
            .then(() => {
                message.success('释放会议室成功')
                onOk()
                setLoadingReleaseAndDelete(false)
                setOpenCreate(true)
            })
            .catch(err => {
                console.error(err)
                message.error('释放会议室失败')
                setLoadingReleaseAndDelete(false)
            })
    }

    return (
        <>
            <Modal
                open={open}
                footer={null}
                title={
                    <div>
                        <div style={{ textAlign: 'center', fontSize: '1.3rem' }}>会议室释放</div>
                    </div>
                }
                onCancel={onCancel}
                onOk={onOk}
                closeIcon={null}
                destroyOnClose
                maskClosable
            >
                {reservation !== undefined && (
                    <div style={{ padding: '0 20px' }}>
                        <Divider style={{ margin: '15px 0' }} />
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'start',
                                    gap: 10,
                                    width: '100%'
                                }}
                            >
                                <div style={{ fontSize: '0.9rem', fontWeight: 'bolder', flex: 1 }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            gap: 4,
                                            justifyContent: 'left',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <IoMdTime />
                                        <div>会议时间：</div>
                                    </div>
                                </div>
                                <div style={{ flex: 3 }}>
                                    {ParseDateTime(reservation.meeting_start_time).time}-
                                    {ParseDateTime(reservation.meeting_end_time).time}
                                </div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'start',
                                    gap: 10,
                                    width: '100%'
                                }}
                            >
                                <div style={{ fontSize: '0.9rem', fontWeight: 'bolder', flex: 1 }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            gap: 4,
                                            justifyContent: 'left',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <SlNote size='0.8rem' />
                                        <div>会议主题：</div>
                                    </div>
                                </div>
                                <div style={{ flex: 3 }}>{reservation.meeting_title}</div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'start',
                                    gap: 10,
                                    width: '100%'
                                }}
                            >
                                <div style={{ fontSize: '0.9rem', fontWeight: 'bolder', flex: 1 }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            gap: 4,
                                            justifyContent: 'left',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <PiUsers />
                                        <div>预约部门：</div>
                                    </div>
                                </div>
                                <div style={{ flex: 3 }}>{reservation.department}</div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'start',
                                    gap: 10,
                                    width: '100%'
                                }}
                            >
                                <div style={{ fontSize: '0.9rem', fontWeight: 'bolder', flex: 1 }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            gap: 4,
                                            justifyContent: 'left',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <MdOutlineMeetingRoom />
                                        <div>预约人：</div>
                                    </div>
                                </div>
                                <div style={{ flex: 3 }}>{reservation.reserver}</div>
                            </div>
                        </div>
                        <Divider style={{ margin: '15px 0' }} />
                        <div style={{ display: 'flex', gap: 8, justifyContent: 'left' }}>
                            <Button
                                danger
                                onClick={() => {
                                    handleReleaseRoom(reservation.meeting_start_time, reservation.meeting_end_time)
                                }}
                                loading={loadingRelease}
                            >
                                释放会议
                            </Button>
                            <Button
                                danger
                                onClick={() => {
                                    handleReleaseRoomAndCreate(
                                        reservation.meeting_start_time,
                                        reservation.meeting_end_time
                                    )
                                    setStartTimestamp(reservation.meeting_start_time)
                                    setEndTimestamp(reservation.meeting_end_time)
                                }}
                                loading={loadingReleaseAndDelete}
                            >
                                释放并创建会议
                            </Button>
                            <Button onClick={() => onCancel()}>取消</Button>
                        </div>
                    </div>
                )}
            </Modal>
            <CreateMeetingForm
                open={openCreate}
                onOk={() => setOpenCreate(false)}
                onCancel={() => setOpenCreate(false)}
                startTimestamp={startTimestamp}
                endTimestamp={endTimestamp}
            />
        </>
    )
}

export default MeetingRoomRelease
