import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Card, Col, List, message, Row, Space } from 'antd'
import axios from 'axios'

export default function Rooms() {
    const apiRooms = `/api/v1/rooms`
    const navigate = useNavigate()

    const [dataSource, setDataSource] = useState([])
    useEffect(() => {
        axios
            .get(apiRooms)
            .then((res) => {
                let arr = []
                res.data.data.map((item) => {
                    arr.push({ title: item })
                })
                console.log(arr)
                //setDataSource(res.data.data)
                setDataSource(arr)
            })
            .catch((err) => {
                navigate(`/login`)
            })
    }, [])
    return (
        <div style={{ height: '100%', width: '100%', overflow: 'scroll', padding: '20px' }}>
            <List
                grid={{
                    gutter: 16,
                    column: 4,
                }}
                dataSource={dataSource}
                renderItem={(item) => (
                    <List.Item>
                        <Card title={item.title} style={{ textAlign: 'center' }}>
                            <div style={{ textAlign: 'center' }}>
                                <Row justify="start">
                                    <Col span={12}>
                                        <Button
                                            type="primary"
                                            onClick={() => {
                                                axios
                                                    .post(`/api/v1/modbus`, {
                                                        action: 'door open',
                                                        room: item.title,
                                                    })
                                                    .then(() => {
                                                        message.info('开门成功')
                                                    })
                                                    .catch(() => {
                                                        message.error('开门失败')
                                                    })
                                            }}
                                        >
                                            开门
                                        </Button>
                                    </Col>
                                    <Col span={12}>
                                        <Button
                                            danger
                                            onClick={() => {
                                                axios
                                                    .post(`/api/v1/modbus`, {
                                                        action: 'door close',
                                                        room: item.title,
                                                    })
                                                    .then(() => {
                                                        message.info('关门成功')
                                                    })
                                                    .catch(() => {
                                                        message.error('关门失败')
                                                    })
                                            }}
                                        >
                                            关门
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    </List.Item>
                )}
            ></List>
        </div>
    )
}
