import moment from 'moment'

// // safari new Date()不兼容的问题
// // 它奶奶的, 这个不兼容性问题我调试了无数遍了.
// // https://cloud.tencent.com/developer/article/1654126
// export function ParseDateTime(str) {
//     if (!str) {
//         return {}
//     }
//     if (str.length === 0) {
//         return {}
//     }
//     var newstr = str.replaceAll('-', '/')
//     var dt = new Date(newstr)
//     var year = dt.getFullYear()
//     var month = dt.getMonth() + 1
//     // getDay: day of week
//     // getDate: day of month
//     var day = dt.getDate()
//     var hour = dt.getHours()
//     var minute = dt.getMinutes()
//     var second = dt.getSeconds()
//
//     //var date = year + '年' + month + '月' + day + '日'
//     //var time = hour + '时' + minute + '分' + second + '秒'
//
//     if (month < 10) {
//         month = '0' + month
//     }
//     if (day < 10) {
//         day = '0' + day
//     }
//     if (hour < 10) {
//         hour = '0' + hour
//     }
//     if (minute < 10) {
//         minute = '0' + minute
//     }
//     if (second < 10) {
//         second = '0' + second
//     }
//     var date = year + '-' + month + '-' + day
//     //var time = hour + ':' + minute + ':' + second
//     var time = hour + ':' + minute
//     return { date, time, dt }
// }

// NOTE: 这个时间戳是微秒, 不是秒和纳秒
export function ParseDateTime(timestamp) {
    if (!timestamp) {
        return {}
    }
    if (timestamp.length === 0) {
        return {}
    }

    //console.log(timestamp)
    //console.log(Date.now())
    const date = moment(timestamp).format('YYYY/MM/DD')
    const time = moment(timestamp).format('HH:mm')
    const dt = new Date(timestamp)

    return { date, time, dt }
}
