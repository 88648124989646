import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import IndexRouter from 'router/IndexRouter'
import 'util/http'
import 'assets/styles/global.scss'
import zh_CN from 'antd/locale/zh_CN'
import { ConfigProvider } from 'antd'
import dayjs from 'dayjs'
dayjs.locale('zh-cn')

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <ConfigProvider locale={zh_CN}>
        <IndexRouter />
    </ConfigProvider>
    // <React.StrictMode>
    // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
