import Logo from '../assets/images/logo.png'
import LogoFavicon from '../assets/images/logo-favicon.png'
import NotFound from '../assets/images/notfound.png'

import XuhuiA01 from '../assets/images/徐汇-一楼-A01.png'
import XuhuiB01 from '../assets/images/徐汇-二楼-B01.png'
import XuhuiB02 from '../assets/images/徐汇-二楼-B02.png'
import XuhuiB03 from '../assets/images/徐汇-二楼-B03.png'
import XuhuiC00 from '../assets/images/徐汇-三楼-接待室.png'
import XuhuiC01 from '../assets/images/徐汇-三楼-C01.png'
import XuhuiC02 from '../assets/images/徐汇-三楼-C02.png'

import JiadingA01 from '../assets/images/嘉定-一楼-A01.png'
import JiadingA02 from '../assets/images/嘉定-一楼-A02.png'
import JiadingA03 from '../assets/images/嘉定-一楼-A03.png'
import JiadingB01 from '../assets/images/嘉定-二楼-B01.png'
import JiadingB02 from '../assets/images/嘉定-二楼-B02.png'
import JiadingC01 from '../assets/images/嘉定-三楼-C01.png'
import JiadingC02 from '../assets/images/嘉定-三楼-C02.png'
import JiadingD01 from '../assets/images/嘉定-四楼-D01.png'
import JiadingD02 from '../assets/images/嘉定-四楼-D02.png'

import WuhuA01 from '../assets/images/芜湖-一楼-A01.png'
import WuhuA02 from '../assets/images/芜湖-一楼-A02.png'
import WuhuB01 from '../assets/images/芜湖-三楼-B01.png'
import WuhuB02 from '../assets/images/芜湖-三楼-B02.png'
import WuhuB03 from '../assets/images/芜湖-三楼-B03.png'
import WuhuB04 from '../assets/images/芜湖-三楼-B04.png'
import WuhuB05 from '../assets/images/芜湖-三楼-B05.png'

function LoadImage(name) {
    switch (name) {
        case 'logo':
            return Logo
        case 'logo-favicon':
            return LogoFavicon

        case '徐汇-一楼-A01':
            return XuhuiA01
        case '徐汇-二楼-B01':
            return XuhuiB01
        case '徐汇-二楼-B02':
            return XuhuiB02
        case '徐汇-二楼-B03':
            return XuhuiB03
        case '徐汇-三楼-接待室':
            return XuhuiC00
        case '徐汇-三楼-C01':
            return XuhuiC01
        case '徐汇-三楼-C02':
            return XuhuiC02

        case '嘉定-一楼-A01':
            return JiadingA01
        case '嘉定-一楼-A02':
            return JiadingA02
        case '嘉定-一楼-A03':
            return JiadingA03
        case '嘉定-二楼-B01':
            return JiadingB01
        case '嘉定-二楼-B02':
            return JiadingB02
        case '嘉定-三楼-C01':
            return JiadingC01
        case '嘉定-三楼-C02':
            return JiadingC02
        case '嘉定-四楼-D01':
            return JiadingD01
        case '嘉定-四楼-D02':
            return JiadingD02

        case '芜湖-一楼-A01':
            return WuhuA01
        case '芜湖-一楼-A02':
            return WuhuA02
        case '芜湖-三楼-B01':
            return WuhuB01
        case '芜湖-三楼-B02':
            return WuhuB02
        case '芜湖-三楼-B03':
            return WuhuB03
        case '芜湖-三楼-B04':
            return WuhuB04
        case '芜湖-三楼-B05':
            return WuhuB05

        default:
            return NotFound
    }
}

export { LoadImage }
