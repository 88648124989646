import React from 'react'
import Error from 'components/Error'

const props = {
    status: '403',
    title: '403',
    subTitle: 'Forbidden',
}
export default function Forbidden() {
    return <Error {...props} />
}
