import React from 'react'
import { Button, Result } from 'antd'
import { useNavigate } from 'react-router-dom'

export default function Error(props) {
    const navigate = useNavigate()
    const handleClick = () => {
        navigate('/')
    }
    return (
        <Result
            status={props.status}
            title={props.title}
            subTitle={props.subTitle}
            extra={
                <Button type="primary" onClick={handleClick}>
                    Back Home
                </Button>
            }
        />
    )
}
