import { Button, DatePicker, Form, Input, message, Modal, Select, Space, TimePicker } from 'antd'
import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import axios from 'axios'
import './index.css'
import { useForm } from 'antd/es/form/Form'
import { SlNote } from 'react-icons/sl'
import { IoMdTime } from 'react-icons/io'
import { MdOutlineMeetingRoom } from 'react-icons/md'
import { PiUsers } from 'react-icons/pi'

const CreateMeetingForm = ({ open, onOk, onCancel, reservations, startTimestamp, endTimestamp }) => {
    // const apiUser = 'http://localhost:8001/api/feishu/user?size=5000'
    // const apiUser = 'https://asset.cowarobot.cn/api/feishu/user?size=5000'
    // const apiUser = 'http://conference2.cowarobot.com/api/v1/feishu/user?size=5000'
    const apiUser = '/api/v1/feishu/user?size=5000'

    // const apiCreateMeeting = 'http://localhost:1080/api/v1/calendar_event2'
    // const apiCreateMeeting = 'http://conference2.cowarobot.com/api/v1/calendar_event2'
    const apiCreateMeeting = '/api/v1/calendar_event2'
    const [top, setTop] = useState('20%')
    const [openSelect, setOpenSelect] = useState(false)

    const [form] = useForm()

    const url = new URL(window.location)
    const pathname = url.pathname.split('/')
    const room = pathname[pathname.length - 1]

    // for (let item of reservations) {
    //     console.log('----- ', dayjs(item.meeting_start_time).format('YYYY-MM-DD HH:mm:ss'))
    //     console.log('----- ', dayjs(item.meeting_end_time).format('YYYY-MM-DD HH:mm:ss'))
    // }

    const [userOptions, setUserOptions] = useState()
    const [loadingSubmit, setLoadingSubmit] = useState(false)

    const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

    const now = dayjs()
    // 禁用当前时间之前的小时
    const disabledHours = () => {
        let hours = []
        for (let i = 0; i < now.hour(); i++) {
            hours.push(i)
        }
        return hours
    }
    // 禁用当前小时内已过的分钟
    const disabledMinutes = selectedHour => {
        let minutes = []
        if (selectedHour === now.hour()) {
            for (let i = 0; i < now.minute(); i++) {
                minutes.push(i)
            }
        }
        return minutes
    }

    useEffect(() => {
        axios.get(apiUser).then(res => {
            // console.warn('-------------', res.data.data.items)
            const options = res.data.data.items.map(item => ({ label: item.name, value: item.open_id }))
            // console.warn(options)
            setUserOptions(options)
        })
        console.warn('--------- startTimestamp', startTimestamp)
        console.warn('--------- endTimestamp', endTimestamp)
        if (startTimestamp && endTimestamp) {
            form.setFieldValue('time_range', [dayjs(startTimestamp), dayjs(endTimestamp)])
        } else {
            form.setFieldValue('time_range', [now])
        }
    }, [startTimestamp, endTimestamp])

    // {
    //     "summary": "test",
    //     "start_time": "2024-05-12T01:44:00.000Z",
    //     "end_time": "2024-05-12T01:47:00.000Z",
    //     "attendees": [
    //         "ou_93f728f7a66d74f0bfd5ebe81e9771cf",
    //         "ou_8bd6313f9ae1c95c3533238b24b82ae3"
    //     ],
    //     "meeting_room": "徐汇-一楼-A01",
    //     "user_id": "48153c26"
    // }

    return (
        <Modal
            open={open}
            footer={null}
            closeIcon={null}
            title={<div style={{ fontSize: '1.5rem', textAlign: 'center', marginBottom: 15 }}>创建会议</div>}
            onOk={onOk}
            onCancel={onCancel}
            destroyOnClose
            maskClosable
            width={550}
            style={{ top: top }}
        >
            <Form
                form={form}
                style={{ margin: '0 30px' }}
                onFinish={values => {
                    const time_range = form.getFieldValue('time_range')
                    if (time_range[0] === undefined || time_range[1] === undefined) {
                        message.error('请选择会议【开始时间】和【结束时间】')
                        return
                    }
                    if (time_range[0].format('YYYY-MM-DD HH:mm:ss') === time_range[1].format('YYYY-MM-DD HH:mm:ss')) {
                        message.error('会议【开始时间】不能等于会议【结束时间】')
                        return
                    }

                    console.info(time_range[0]?.format('YYYY-MM-DD HH:mm:ss'))
                    console.info(time_range[1]?.format('YYYY-MM-DD HH:mm:ss'))
                    const data = {
                        summary: form.getFieldValue('summary'),
                        start_time: time_range[0],
                        end_time: time_range[1],
                        attendees: form.getFieldValue('attendees'),
                        meeting_room: decodeURIComponent(room),
                        user_id: '48153c26' // 公共账号1的 user id
                    }
                    console.warn(data)
                    setLoadingSubmit(true)
                    axios
                        .post(apiCreateMeeting, data, {
                            auth: {
                                username: 'oq9dPZ4hQw8UjC7PYVP2m98K06qA56',
                                password: '6pcthpA8ybgZIZaTCIPbZVu7xQE1O3'
                            }
                        })
                        .then(res => {
                            setLoadingSubmit(false)
                            if (res.data.code === 0) {
                                message.success('创建会议成功')
                                onOk()
                                form.resetFields()
                                if (startTimestamp && endTimestamp) {
                                    form.setFieldValue('time_range', [dayjs(startTimestamp), dayjs(endTimestamp)])
                                } else {
                                    form.setFieldValue('time_range', [now])
                                }
                            } else {
                                message.error('创建会议失败: ' + res.data.msg)
                                return
                            }
                        })
                        .catch(err => {
                            setLoadingSubmit(false)
                            console.error(err.response)
                            // message.error('创建会议失败')
                            message.error('创建会议失败: ' + err.response?.data?.msg)
                        })

                    return
                }}
            >
                <Form.Item
                    name='summary'
                    label=<div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', gap: 4 }}>
                        <SlNote size='0.8rem' />
                        <div>会议主题</div>
                    </div>
                    labelCol={{ span: 5 }}
                >
                    <Input placeholder='请输入会议主题' />
                </Form.Item>
                <Form.Item
                    label=<div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', gap: 4 }}>
                        <IoMdTime />
                        <div>会议时间</div>
                    </div>
                    name='time_range'
                    labelCol={{ span: 5 }}
                    rules={[{ required: true, message: '请选择会议时间' }]}
                >
                    <TimePicker.RangePicker
                        showHour
                        showMinute
                        showSecond={false}
                        minuteStep={10}
                        disabledTime={() => ({ disabledHours, disabledMinutes })}
                        style={{ width: '100%' }}
                        inputReadOnly // 设置输入框为只读（避免在移动设备上打开虚拟键盘）
                        // changeOnScroll
                    />
                </Form.Item>
                <Form.Item
                    name='attendees'
                    label=<div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', gap: 4 }}>
                        <PiUsers />
                        <div>参会人</div>
                    </div>
                    labelCol={{ span: 5 }}
                    rules={[{ required: true, message: '请选择一个或多个参会人' }]}
                >
                    <Select
                        open={openSelect}
                        onDropdownVisibleChange={openSelect => {
                            if (typeof top === 'string') {
                                setOpenSelect(false)
                                return
                            }
                            setOpenSelect(openSelect)
                        }}
                        options={userOptions}
                        placeholder='请选择一个或多个参会人'
                        mode='multiple'
                        showSearch
                        allowClear
                        filterOption={filterOption}
                        onMouseLeave={() => {
                            setTop('20%')
                            setOpenSelect(false)
                        }}
                        onMouseEnter={() => {
                            setTop(20)
                            setOpenSelect(false)
                        }}
                        onFocus={() => {
                            setTop(20)
                            setOpenSelect(false)
                        }}
                        onSelect={() => setOpenSelect(false)}
                    />
                </Form.Item>
                <Form.Item
                    name='meeting_room'
                    label=<div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', gap: 4 }}>
                        <MdOutlineMeetingRoom />

                        <div>会议室</div>
                    </div>
                    labelCol={{ span: 5 }}
                    required
                >
                    <Input disabled placeholder={decodeURIComponent(room)} />
                </Form.Item>
                <div style={{ display: 'flex', gap: 8, justifyContent: 'end' }}>
                    <Button
                        onClick={() => {
                            onCancel()
                            form.resetFields()
                            if (startTimestamp && endTimestamp) {
                                form.setFieldValue('time_range', [dayjs(startTimestamp), dayjs(endTimestamp)])
                            } else {
                                form.setFieldValue('time_range', now)
                            }
                            setLoadingSubmit(false)
                        }}
                    >
                        取消
                    </Button>
                    <Button type='primary' htmlType='submit' loading={loadingSubmit}>
                        提交
                    </Button>
                </div>
            </Form>
        </Modal>
    )
}

export default CreateMeetingForm
