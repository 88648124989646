import axios from 'axios'
import React, { Component } from 'react'
import { SpinLoading, Button } from 'antd-mobile'
import style from './css/style.module.css'
import { ParseDateTime } from 'util/time'
import withRouter from 'components/withRouter'
import { message } from 'antd'

//2002: 该会议室您没有预定
//2003: 该会议室已被预定
//2004: 请在会议时间段内开门
const meetingStateMap = {
    // 后端可能返回的业务状态码
    OpenDoor: 0,
    NotReserved: 2002,
    AlreadyReserved: 2003,
    NotMeetingTime: 2004
}

export class MeetingRoom extends Component {
    //// 测试数据
    //state = {
    //    meetingInfo: {
    //        meeting_title: '会议预约信息发布',
    //        department: '运维团队',
    //        reserver: '彭昌杰',
    //        meeting_start_time: '16:30:00',
    //        meeting_end_time: '17:30:00',
    //        meeting_room: '徐汇-三楼-C01',
    //    },
    //    code: 2004,
    //}

    state = {
        ok: false,
        meetingInfo: {
            meeting_title: '',
            department: '',
            reserver: '',
            meeting_start_time: '',
            meeting_end_time: '',
            meeting_room: ''
        }
    }

    // backend = 'http://conference2.cowarobot.com'
    // //backend = 'http://127.0.0.1:1080'
    // //apiMeetingRoom = this.backend + this.props.location.pathname + this.props.location.search
    // //apiOpendoor = this.backend + '/api/v1/door/open'
    // apiMeetingRoom = this.backend + '/api/v1/qrcode' + this.props.location.search
    // apiOpendoor = this.backend + '/api/v1/door/open'

    apiMeetingRoom = '/api/v1/qrcode' + this.props.location.search
    //apiOpendoor = '/api/v1/door/open'
    apiOpendoor = '/api/v1/modbus'

    // 会议室的名字在 query parameter 中,我们需要在 componentDidMount 中解析出来
    room = ''

    componentDidMount() {
        document.title = '会议室扫码开门与预约系统'
        console.log('props', this.props)
        console.log('props.location.search', this.props.location.search)
        console.log('backend', this.backend)
        console.log('apiMeetingRoom', this.apiMeetingRoom)
        console.log('apiOpendoor', this.apiOpendoor)

        // 获取到当前 uri 中的 room 参数, 用来给点击开门后, 然后向后端api发送命令时就可以知道开哪个门.
        //
        // query 的值类似于: "room=徐汇-一楼-A01&code=e86sef827d3048e49bdbbe01d30f3887"
        // 我们需要找出 room 参数对应的值.
        //
        const query = this.props.location.search.substring(1)
        let vars = query.split('&')
        console.log(vars)
        for (let i = 0; i < vars.length; i++) {
            let items = vars[i].split('=')
            if (items[0] === 'room') {
                this.room = items[1]
                break
            }
        }
        console.log('room: ', this.room)

        // 飞书重定向到我之后, 拿着这个它的 query parameters 去向后端拿数据
        // 必须要有 room 参数, 如果是生产环境也必须要有 code 参数(登录预授权码)
        axios.get(this.apiMeetingRoom).then(res => {
            console.log(this.props.location.pathname + this.props.location.search)
            console.log(res.data)
            if (res.data.data.length !== 0) {
                this.setState({
                    meetingInfo: JSON.parse(res.data.data),
                    code: res.data.code,
                    ok: true
                })
            } else {
                this.setState({
                    code: res.data.code,
                    ok: true
                })
            }
            //// 用来做测试的, 生产环境必须把这个注释掉
            //this.setState({
            //    ok: true,
            //    code: 2002,
            //})
        })
    }

    whichFootComponent() {
        switch (this.state.code) {
            case meetingStateMap.OpenDoor: // 可以开门
                return (
                    <FootButton
                        action='开门'
                        prompt='您已预定或参与本会议, 请点击开门'
                        api={this.apiOpendoor}
                        room={this.room}
                    />
                )
            case meetingStateMap.NotReserved: // 会议室没有预定,需要预约
                return <FootButton action='预约' prompt='您未预定本会议室，请在飞书上预定后使用' /> // 这个 api 测试使用,待会删掉
            case meetingStateMap.AlreadyReserved: // 会议室已被其他人预定,需要申述
                return <FootButton action='申诉使用' prompt='会议室已被预约, 您无权开门' />
            case meetingStateMap.NotMeetingTime: // 提前太早开会议室的门,依然是预约
                return <FootButton action='预约' prompt='您未预定本会议室，请在飞书上预定后使用' />
            default:
                return <FootEmpty />
        }
    }
    render() {
        if (!this.state.ok) {
            return (
                <div className={style.spinLoading}>
                    <SpinLoading color='primary' style={{ '--size': '96px' }} />
                </div>
            )
        }
        return (
            <div className={style.root}>
                <div className={style.container}>
                    <div className={style.header}>
                        <h2>会议预约信息</h2>
                    </div>
                    <div className={style.content}>
                        <p className={style.contentTop}>会议主题: {this.state.meetingInfo.meeting_title}</p>
                        <p>预约部门: {this.state.meetingInfo.department}</p>
                        <p>预 约 人: {this.state.meetingInfo.reserver}</p>
                        <p>
                            会议时间: {ParseDateTime(this.state.meetingInfo.meeting_start_time).time}-
                            {ParseDateTime(this.state.meetingInfo.meeting_end_time).time}
                        </p>
                        <p className={style.contentBottom}>会议室号: {this.state.meetingInfo.meeting_room}</p>
                    </div>
                    {console.log(this.state.code)}
                    {this.whichFootComponent()}
                </div>
            </div>
        )
    }
}

class FootButton extends Component {
    render() {
        switch (this.props.action) {
            case '开门':
                return (
                    <div>
                        <div className={style.prompt}>{this.props.prompt}</div>
                        <div className={style.buttonWrap}>
                            <Button
                                color='primary'
                                onClick={() => {
                                    console.log(this.props.action + ' ' + this.props.api + ' ' + this.props.room)
                                    //axios.get(this.props.api)
                                    axios
                                        .post(this.props.api, {
                                            action: 'open',
                                            room: this.props.room
                                        })
                                        .then(res => {
                                            alert('开门成功')
                                        })
                                        .catch(err => {
                                            console.error(err)
                                            alert('开门失败: ', +err)
                                        })
                                }}
                            >
                                {this.props.action}
                            </Button>
                        </div>
                    </div>
                )
            case '预约':
                return (
                    <div>
                        <div className={style.prompt}>{this.props.prompt}</div>
                        <div>
                            <Button
                                color='primary'
                                onClick={() => {
                                    window.location.replace(`https://applink.feishu.cn/client/calendar/event/create`)
                                }}
                            >
                                点击预定
                            </Button>
                        </div>
                    </div>
                )

            case '申诉使用':
                return <div className={style.prompt}>{this.props.prompt}</div>
            default:
                console.log('unknown action: ', this.props.action)
                return <div></div>
        }
    }
}
class FootEmpty extends Component {
    render() {
        return <div className={style.prompt}></div>
    }
}

export default withRouter(MeetingRoom)
