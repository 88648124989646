import React from 'react'
import Error from 'components/Error'

const props = {
    status: '500',
    title: '500',
    subTitle: 'Server Busy',
}

export default function InternalServerError() {
    return <Error {...props} />
}
