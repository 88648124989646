import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Forbidden from 'views/Error/Forbidden'
import InternalServerError from 'views/Error/InternalServerError'
import NotFound from 'views/Error/NotFound'
import Login from 'views/Login'
import Meeting from 'views/Meeting'
import Qrcode from 'views/Qrcode'
import Reservations from 'views/Reservations'
import Rooms from 'views/Rooms'
import Status from 'views/Status'
import Statusv1 from 'views/Statusv1'
import Sandbox from '../views/Sandbox'

// Router6 版本

export default function IndexRouter() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/status/:room' element={<Status />} />
                <Route path='/statusv1/:room' element={<Statusv1 />} />
                <Route path='/qrcode' element={<Qrcode />} />
                <Route path='/login' element={<Login />} />
                <Route path='/meeting' element={<Meeting />} />

                <Route path='/' element={<Sandbox />}>
                    <Route path='' element={<Rooms />} />
                    <Route path='/rooms' element={<Rooms />} />
                    <Route path='/reservations' element={<Reservations />} />
                </Route>

                <Route path='*' element={<NotFound />} />
                <Route path='/404' element={<NotFound />} />
                <Route path='/403' element={<Forbidden />} />
                <Route path='/500' element={<InternalServerError />} />
            </Routes>
        </BrowserRouter>
    )
}

// // Router5 版本
// // import { BrowserRouter, Route, Switch } from 'react-router-dom'
// export default class IndexRouter extends Component {
//     render() {
//         return (
//             <div>
//                 <BrowserRouter>
//                     <Switch>
//                         <Route path="/qrcode" component={Qrcode} />
//                         <Route path="/rooms" component={Rooms} />
//                         <Route path="/reservations" component={Reservations} />
//                         <Route path="/status" component={Status} />
//                         <Route path="/" component={Qrcode} />
//                         <Route component={NotFound} />
//                     </Switch>
//                 </BrowserRouter>
//             </div>
//         )
//     }
// }
