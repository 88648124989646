import axios from 'axios'

// const baseURL = 'http://localhost:1080'
const baseURL = 'http://conference2.cowarobot.com'
axios.defaults.baseURL = baseURL

// Add a request interceptor
axios.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        const cowa_meeting_token = localStorage.getItem('cowa_meeting_token')
        if (cowa_meeting_token) {
            config.headers.Authorization = 'Bearer ' + JSON.parse(cowa_meeting_token).token
        }
        return config
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error)
    }
)

// Add a response interceptor
axios.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error)
    }
)
