import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Table } from 'antd'
import axios from 'axios'

export default function Rervations() {
    const [dataSource, setDataSource] = useState([])
    const navigate = useNavigate()

    const columns = [
        {
            title: 'meeting_title',
            dataIndex: 'meeting_title',
            key: 'meeting_title',
        },
        {
            title: 'department',
            dataIndex: 'department',
            key: 'department',
        },
        {
            title: 'reserver',
            dataIndex: 'reserver',
            key: 'reserver',
        },
        {
            title: 'meeting_room',
            dataIndex: 'meeting_room',
            key: 'meeting_room',
        },
        {
            title: 'meeting_start_time',
            dataIndex: 'meeting_start_time',
            key: 'meeting_start_time',
        },
        {
            title: 'meeting_end_time',
            dataIndex: 'meeting_end_time',
            key: 'meeting_end_time',
        },
    ]

    useEffect(() => {
        axios
            .get(`/api/v1/reservations`)
            .then((res) => {
                console.log(res.data.data)
                setDataSource(res.data.data)
            })
            .catch((err) => {
                navigate(`/login`)
            })
    }, [])
    return (
        <Table
            rowKey={(item) => item.meeting_room + item.meeting_start_time}
            dataSource={dataSource}
            columns={columns}
            pagination={{ pageSize: 10 }}
        ></Table>
    )
}
