import React, { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    DashboardOutlined,
    UserOutlined,
    CodeSandboxOutlined,
    SmileOutlined,
} from '@ant-design/icons'
import { Layout, Menu, Button, theme, Dropdown, Space, Avatar } from 'antd'
import { LoadImage } from 'components/LoadImage'

const { Header, Sider, Content } = Layout
const slideMenuItems = [
    // {
    //     key: '/dashboard',
    //     icon: <DashboardOutlined />,
    //     label: '面板',
    // },
    {
        key: '/rooms',
        icon: <DashboardOutlined />,
        label: '会议室',
    },
    {
        key: '/reservations',
        icon: <CodeSandboxOutlined />,
        label: '会议室预定信息',
    },
]

export default function Sandbox() {
    const [collapsed, setCollapsed] = useState(false)
    const {
        token: { colorBgContainer },
    } = theme.useToken()
    const navigate = useNavigate()
    const location = useLocation()
    const menuClick = (e) => {
        navigate(e.key)
    }
    const [currentUser, setCurrentUser] = useState('')
    const items = [
        {
            key: 1,
            label: `${currentUser}`,
            icon: <SmileOutlined />,
        },
        {
            key: 0,
            label: '退出登录',
            danger: true,
        },
    ]
    const onClick = (evt) => {
        // 这里的 key 的类型不是 number, 是 string
        if (evt.key === '0') {
            localStorage.removeItem('cowa_meeting_token')
            navigate('/login')
        }
    }

    useEffect(() => {
        const cowa_meeting_token = localStorage.getItem('cowa_meeting_token')
        if (cowa_meeting_token) {
            setCurrentUser(JSON.parse(cowa_meeting_token).username)
        }
    }, [])
    return (
        <Layout>
            <Sider trigger={null} collapsible collapsed={collapsed}>
                <div className="demo-logo-vertical" />
                <div>
                    <img
                        src={LoadImage('logo-favicon')}
                        alt="logo-favicon"
                        style={{ width: '60%', margin: '5% 20%' }}
                    ></img>
                </div>
                <Menu
                    theme="dark"
                    mode="inline"
                    defaultSelectedKeys={[location.pathname]}
                    items={slideMenuItems}
                    onClick={menuClick}
                />
            </Sider>
            <Layout>
                <Header style={{ padding: 0, background: colorBgContainer }}>
                    <Button
                        type="text"
                        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                        onClick={() => setCollapsed(!collapsed)}
                        style={{
                            fontSize: '16px',
                            width: 64,
                            height: 64,
                        }}
                    />
                    <div style={{ float: 'right' }}>
                        <span>
                            当前用户 <span style={{ color: '#1890ff' }}>{currentUser}</span>
                        </span>
                        <Dropdown menu={{ items, onClick }}>
                            <Space>
                                <Avatar size={'large'} icon={<UserOutlined />} />
                            </Space>
                        </Dropdown>
                    </div>
                </Header>
                <Content>
                    <Outlet />
                </Content>
            </Layout>
        </Layout>
    )
}
