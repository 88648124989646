import React, { useCallback } from 'react'
import { Form, Input, Button, message } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import Particles from 'react-particles'
import { loadFull } from 'tsparticles'
import style from 'views/Login/login.module.scss'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

export default function Login() {
    const navigate = useNavigate()
    const onFinish = (value) => {
        axios
            .post(`/api/v1/login`, {
                username: value.username,
                password: value.password,
            })
            .then((res) => {
                if (res.status === 200) {
                    const token = res.data.data
                    console.log(token)
                    localStorage.setItem('cowa_meeting_token', JSON.stringify(token))
                    navigate('/')
                } else {
                    message.error('登录失败')
                }
            })
            .catch((err) => {
                message.error('登录失败, 用户名或密码错误')
            })
    }
    const onFinishFailed = () => {
        console.log('onFinishFailed')
    }
    const particlesInit = useCallback(async (engine) => {
        //console.log(engine)
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine)
    }, [])
    const particlesLoaded = useCallback(async (container) => {
        //await console.log(container)
    }, [])
    return (
        <div
            style={{
                background: 'rgba(35,39,65)',
                height: '100%',
                overflow: 'hidden',
            }}
        >
            <Particles
                init={particlesInit}
                loaded={particlesLoaded}
                url="/particles-kitty.json"
                height={document.documentElement.clientHeight.toString()}
            />
            <div className={style.formContainer}>
                <div className={style.loginTitle}>酷哇会议室管理系统</div>
                <Form
                    name="basic"
                    style={{ maxWidth: 600 }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your username!',
                            },
                        ]}
                    >
                        <Input prefix={<UserOutlined />} placeholder="Username" />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                    >
                        <Input.Password prefix={<LockOutlined />} placeholder="Password" />
                    </Form.Item>

                    <Form.Item style={{ textAlign: 'center' }}>
                        <Button type="primary" htmlType="submit">
                            登录
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}
